import React from 'react';

import { Box, Divider } from '@mui/material';

import styles from '../../common/styling/styles';
import { SubText } from '../../common/texts';

const BlogCategoryDate = ({ category, date, color = styles.grey }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        mt: 1,
        justifyContent: 'space-between',
      }}
    >
      <SubText
        variant="body2"
        marginY={styles.allZero}
        textAlignment={styles.alignStyleCCLL}
        fontWeight={700}
        value={category}
        fontColor={color}
      />

      <SubText
        variant="body2"
        marginY={styles.allZero}
        textAlignment={styles.alignStyleCCLL}
        fontWeight={700}
        fontColor={color}
        value={date}
      />
    </Box>
  );
};

export default BlogCategoryDate;
