import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { Grid, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    textContainer: {
      display: 'flex',
      flexDirection: 'column',
      // paddingLeft: 20, // change these to only be on 900px+
      [theme.breakpoints.up('md')]: {
        paddingLeft: 80,
      },
    },
  })
);

const SplitTextAndImageColumns = ({
  textComponents,
  images,
  imagesRight = true,
}) => {
  const classes = useStyles();
  return (
    <Grid container sx={{ flexDirection: imagesRight ? 'row' : 'row-reverse' }}>
      <Grid item xs={12} md={6}>
        <div className={classes.textContainer}>
          {textComponents.map((comp) => comp)}
        </div>
      </Grid>
      <Grid item xs={12} md={6}>
        {images.map((img) => {
          const { asset, altText } = img;
          const { gatsbyImageData } = asset;
          return (
            <div key={altText} style={{ marginBottom: 10 }}>
              <GatsbyImage
                image={gatsbyImageData}
                alt={altText || ''}
                objectFit="contain"
                style={{
                  width: '100%',
                  height: '100%',
                  borderRadius: '0px 0px 10px 10px',
                }}
              />
            </div>
          );
        })}
      </Grid>
    </Grid>
  );
};

export default SplitTextAndImageColumns;
