import React from 'react';

import { Typography } from '@mui/material';

import styles from '../styling/styles';

const FanGoTitle: React.FC<{
  title: string;
  marginBottom?: number;
  variant?: string;
  textAlign?: string;
  color?: string;
}> = ({
  title,
  marginBottom = 3,
  variant,
  textAlign = 'center',
  color = styles.darkBlue,
}) => {
  return (
    <Typography
      variant={variant || 'h2'}
      align={textAlign}
      color={color}
      sx={{ marginBottom: marginBottom }}
    >
      {title}
    </Typography>
  );
};

export default FanGoTitle;
