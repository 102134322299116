import React, { useState } from 'react';

import { Box, Divider, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

import { getLink } from '../../../utils/getLink';
import styles from '../../common/styling/styles';
import formatDate from '../../common/utils/formatDate';
import BlogCategoryDate from './BlogCategoryDate';
import BlogImageGatsbyImage from './BlogPageHeadComps/BlogImageGatsbyImage';
import BlogTitleExcerpt from './BlogTitleExcerpt';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: (isHovered) => ({
      borderRadius: 10,
      margin: 10,
      boxShadow: '0 3px 10px rgb(140, 152, 164, 0.3)',
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      transition: 'top 0.3s ease',
      top: isHovered ? '-10px' : '0px',
    }),
  })
);

const Blog = ({ data, marginX = 0, shadow = null }) => {
  const [isHovered, setIsHovered] = useState(false);
  const classes = useStyles(isHovered);
  const { title, slug, excerpt, category, mainImage, publishedAt } = data;
  const { asset, altText } = mainImage;
  const image = asset?.gatsbyImageData;

  const date = formatDate(publishedAt, 'DD MMM, YYYY');

  return (
    <a
      href={getLink({ key: `blog/${slug.current}` })}
      style={{ textDecoration: 'none', display: 'flex', height: '100%' }}
    >
      <div
        className={classes.container}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <BlogImageGatsbyImage altText={altText} image={image} />

        <BlogTitleExcerpt
          title={title}
          excerpt={excerpt}
          headerVariant="h6"
          bodyVariant="body2"
          bodyMarginY={styles.allTwo}
          category={category}
          date={date}
        />
        <Box sx={{ p: 1 }}>
          <Divider sx={{ borderStyle: 'dashed' }} />
          <BlogCategoryDate category={category} date={date} />
        </Box>
      </div>
    </a>
  );
};

export default Blog;

{
  /* <Box
            sx={{
              mt: 2,
              cursor: 'pointer',
              mx: marginX,
              height: '95%',
              boxShadow: 2,
              borderRadius: 2,
            }}
          >

          </Box> */
}

// <BlogCard shadow={shadow}>
// {/* <BlogImage
// altText={mainImage.asset.altText}
// url={mainImage.asset.url}
// height="400px"
// borderRadius="0px"
// /> */}
// <BlogImageGatsbyImage
//   altText={altText}
//   image={image}
//   height="400px"
// />

// <Box
//   sx={{
//     position: 'relative',
//     py: 3,
//     px: 3,
//   }}
// >
//   <BlogTitleExcerpt
//     title={title}
//     excerpt={excerpt}
//     headerVariant="h6"
//     bodyVariant="body2"
//     bodyMarginY={styles.allTwo}
//     category={category}
//     date={date}
//   />
// </Box>
// </BlogCard>
