import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

import { Box, Theme } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    imageContainer: {
      borderRadius: '8px 8px 0 0',
      height: '300px',
      minHeight: '300px',
    },
  })
);

const BlogImageGatsbyImage = ({ image, altText }) => {
  const classes = useStyles();
  return (
    <Box className={classes.imageContainer}>
      <GatsbyImage
        image={image}
        alt={altText || ''}
        objectFit="cover"
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '8px 8px 0 0',
        }}
      />
    </Box>
  );
};

export default BlogImageGatsbyImage;
