import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';

import { Box } from '@mui/material';

import Body from '../../common/body/Body';
import FanGoBody from '../../common/body/FanGoBody';
import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';

const WhyWeddingFeature = ({ data }) => {
  const { weddingFeature, weddingImages } = data;

  const { title, _rawBody: overviewParagraphs } = weddingFeature;

  return (
    <FanGoBody>
      <Box
        sx={{
          display: { md: 'grid' },
          gridTemplateColumns: '5fr 4fr',
          p: 1,
          gap: 2,
          overflow: 'hidden',
          pb: { xs: 4, md: 0 },
        }}
      >
        <Box sx={{ pr: { md: 4 } }}>
          <MainText
            textVariant="h2"
            value={title}
            fontColor={styles.deepGreen}
            textAlignment={styles.alignStyleLLLL}
          />
          {overviewParagraphs.map((paragraph) => (
            <SubText
              key={paragraph._key}
              variant="subtitle1"
              fontColor={styles.grey}
              textAlignment={styles.alignStyleJJJJ}
              value={paragraph.children[0].text}
            />
          ))}
        </Box>

        <Box
          sx={{
            display: 'grid',
            gridTemplateRows: '3fr 2fr',
            gap: 1,
            borderRadius: '8px',
            overflow: 'hidden',
          }}
        >
          {weddingImages.map((image) => {
            const { altText, asset } = image;

            return (
              <Box
                aria-label={altText}
                key={altText}
                sx={{
                  minHeight: 300,
                  backgroundImage: `url(${asset.url})`,
                  backgroundPosition: 'center',
                  backgroundRepeat: 'repeat-y',
                  backgroundSize: 'cover',
                }}
              />
            );
          })}
        </Box>
      </Box>
    </FanGoBody>
  );
};

export default WhyWeddingFeature;
