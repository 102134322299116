import React from 'react';

import { Box } from '@mui/material';

import { getLink } from '../../../../utils/getLink';
import Body from '../../../common/body/Body';
import FanGoButton from '../../../common/button/FanGoButton';
import styles from '../../../common/styling/styles';
import Blog from '../../BlogPageComps/Blog';
import BlogsCarousel from './BlogsCarousel';
import PreviewHeader from './PreviewHeader';

const BlogsPreview = ({ data, type = 'all' }) => {
  const { title, buttonName, blogs } = data;

  let foundBlogs = blogs;

  if (type === 'caseStudy') {
    foundBlogs = blogs.filter((blog) => blog.category === 'Case Study');
  }

  const filteredBlogs = foundBlogs.filter((blog) => {
    if (process.env.GATSBY_STAGING_SITE_VAR) {
      return true;
    }

    return new Date(blog.publishedAt) <= new Date();
  });

  const blogsArray = filteredBlogs.map((blog) => {
    return (
      <Blog key={blog.slug.current} data={blog} marginX={0.5} shadow={3} />
    );
  });

  return (
    <Body paddingY={styles.allTen} backgroundColor={styles.lightGrey}>
      <PreviewHeader data={{ title, buttonName, type }} />

      <BlogsCarousel array={blogsArray} />

      <Box
        sx={{
          display: { xs: type !== 'caseStudy' ? 'block' : 'none', md: 'none' },
          textAlign: 'center',
        }}
      >
        <FanGoButton link={getLink({ key: 'blog' })} buttonName={buttonName} />
      </Box>
    </Body>
  );
};

export default BlogsPreview;
