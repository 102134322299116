import React from "react";
import { getLink } from "../../../../utils/getLink";
import FanGoButton from "../../../common/button/FanGoButton";
import styles from "../../../common/styling/styles";
import { MainText } from "../../../common/texts";
import { Box } from "@mui/material";

const PreviewHeader = ({ data }) => {
  return (
    <Box
      sx={{
        display: { xs: "block", md: "flex" },
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        mb: 2,
      }}
    >
      <Box sx={{ flex: 1 }}>
        <MainText
          textVariant="h2"
          textAlignment={styles.alignStyleCCLL}
          value={data.title}
          fontColor={styles.deepGreen}
        />
      </Box>

      <Box
        sx={{
          display: {
            xs: "none",
            md: data.type !== "caseStudy" ? "block" : "none",
          },
        }}
      >
        <FanGoButton
          link={getLink({ key: "blog" })}
          buttonName={data.buttonName}
        />
      </Box>
    </Box>
  );
};

export default PreviewHeader;
