import React from 'react';

import { Box } from '@mui/material';

import { FanGoGridCarousel } from '../../../common/carousel';

const responsiveSizes = [
  {
    breakpoint: 1425,
    cols: 2,
    rows: 1,
  },
  {
    breakpoint: 900,
    cols: 1,
    rows: 1,
  },
];

const BlogsCarousel = ({ array }) => {
  return (
    <>
      <Box sx={{ display: { xs: 'block', lg: 'none' }, mb: 2 }}>
        <FanGoGridCarousel
          array={array}
          responsiveLayout={responsiveSizes}
          mobileBreakpoint={10}
          showDots={false}
          hideArrow={array.length < 2 ? true : false}
          left="10%"
          right="10%"
          autoplay={false}
          padding="5px"
        />
      </Box>

      <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
        <FanGoGridCarousel
          array={array}
          rows={1}
          cols={3}
          mobileBreakpoint={10}
          showDots={false}
          hideArrow={array.length <= 3 ? true : false}
          bottom="50%"
          left="-5%"
          right="-5%"
          autoplay={false}
          padding="5px"
        />
      </Box>
    </>
  );
};

export default BlogsCarousel;
