const truncateText = (text, limit = 90) => {
  if (text.length <= limit) {
    return text;
  }

  // Check if the next word boundary exceeds the limit.
  const nextWordBoundary = text.indexOf(" ", limit);
  if (nextWordBoundary !== -1) {
    limit = nextWordBoundary;
  }

  // Add an ellipsis to the end of the truncated text.
  return text.slice(0, limit) + "...";
};

export default truncateText;
