import { graphql } from 'gatsby';
import React from 'react';

import StandardSEO from '../components/common/seo/StandardSEO';
import Separator from '../components/common/styling/Separator';
import { TrustedBrandIcons } from '../components/PageComps/CommonComps';
import BlogsPreview from '../components/PageComps/CommonComps/blogsPreview/BlogsPreview';
import FullImageBackgroundHeader from '../components/PageComps/CommonComps/heroSections/FullImageBackgroundHeader';
import BasicTitleSubTitle from '../components/PageComps/CommonComps/heroSections/heroSectionComps/BasicTitleSubTitle';
import AboutWeddingFeature from '../components/PageComps/WeddingsPageComp/AboutWeddingFeature';
import WeddingTestimonials from '../components/PageComps/WeddingsPageComp/WeddingTestimonials';
import WeddingVisualTablePlans from '../components/PageComps/WeddingsPageComp/WeddingVisualTablePlans';
import WhyWeddingFeature from '../components/PageComps/WeddingsPageComp/WhyWeddingFeature';

const WeddingsPage = ({ data }) => {
  const { sanityWeddingsPage } = data;
  const {
    pageHead,
    testimonials,
    headSectionBackgroundImage,
    weddingVisualTablePlans,
  } = sanityWeddingsPage;
  const { title, subTitle } = pageHead;

  const { asset, altText } = headSectionBackgroundImage;
  const { gatsbyImageData } = asset;

  return (
    <>
      <FullImageBackgroundHeader
        backgroundPosition="50% 50%"
        imageData={gatsbyImageData}
        shadow="0.55"
        content={
          <BasicTitleSubTitle
            title={title}
            subTitle={subTitle}
            subTitlePlainText={true}
            titleRowSpacing={0}
            titlePaddingBottom={30}
            largeSubTitleSize
          />
        }
      />

      {/* <QuestionsSection data={sanityWeddingsPage.questions} /> */}

      <AboutWeddingFeature
        data={{
          backgrounds: data.sanityCommonContent.backgrounds,
          weddingSoftwareOptions: sanityWeddingsPage.weddingSoftwareOptions,
        }}
      />

      <WeddingVisualTablePlans
        weddingVisualTablePlans={weddingVisualTablePlans}
      />

      <WhyWeddingFeature
        data={{
          weddingFeature: sanityWeddingsPage.weddingFeature,
          weddingImages: sanityWeddingsPage.weddingImages,
        }}
      />

      {/* <Reviews data={sanityWeddingsPage.weddingFeatureReviews} /> */}

      <WeddingTestimonials testimonials={testimonials} />

      <TrustedBrandIcons
        data={data.sanityCommonContent.trustedBy}
        paddingBottom={5}
      />

      <BlogsPreview
        data={{
          ...data.sanityHomePage.blogsPreview,
          blogs: data.allSanityPost.nodes,
        }}
      />
    </>
  );
};

export default WeddingsPage;

export const Head = ({ data }) => (
  <StandardSEO SEOData={data.sanityWeddingsPage.seo} />
);

export const query = graphql`
  query ($language: String!) {
    sanityWeddingsPage(language: { eq: $language }) {
      seo {
        title
        description
        keywords
        name
        type
      }
      weddingVisualTablePlans {
        title
        _rawSubTitle
        featuresTitle
        features {
          title
          subTitle
        }
        images {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 800, formats: [WEBP])
          }
          altText
        }
      }
      weddingSoftwareOptions {
        body: _rawBody
        featuresList {
          _key
          title
          text
          cardImage {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 300, formats: [WEBP])
            }
            altText
          }
        }
      }
      pageHead {
        title
        subTitle
      }
      headSectionBackgroundImage {
        asset {
          gatsbyImageData(placeholder: BLURRED, width: 2000, formats: [WEBP])
        }
        altText
      }
      questions {
        children {
          _key
          text
        }
      }
      introduction {
        body: _rawBody
        feature: howItWorks {
          children {
            _key
            text
          }
        }
      }
      weddingFeature {
        title
        _rawBody
      }
      weddingImages {
        altText
        asset {
          url
        }
      }
      testimonials {
        title
        _rawBody
        clientTestimonials {
          clientLogoImage {
            asset {
              gatsbyImageData(placeholder: BLURRED, width: 300, formats: [WEBP])
            }
          }
          clientPropertyImage {
            asset {
              url
            }
          }
          clientTestimony {
            jobTitle
            testimony
            company
            companyWebsiteURL
            clientName
          }
        }
      }
    }
    sanityCommonContent(language: { eq: $language }) {
      backgrounds {
        altText
        asset {
          url
        }
      }
      trustedBy {
        title
      }
    }
    sanityHomePage(language: { eq: $language }) {
      blogsPreview {
        title
        buttonName
      }
    }
    allSanityPost(
      sort: { publishedAt: DESC }
      filter: { language: { eq: $language } }
    ) {
      nodes {
        title
        slug {
          current
        }
        publishedAt
        author
        excerpt
        mainImage {
          asset {
            gatsbyImageData(placeholder: BLURRED, width: 600, formats: [WEBP])
          }
          altText
        }
        category
        paragraphs {
          title
          body: _rawBody
          image {
            asset {
              altText
              url
            }
          }
        }
      }
    }
  }
`;
