import React from 'react';

import { Typography, useMediaQuery } from '@mui/material';

import SplitTextAndImageColumns from '../../common/alternatingTextAndImage/SplitTextAndImageColumns';
import FanGoBody from '../../common/body/FanGoBody';
import styles from '../../common/styling/styles';
import FanGoTitle from '../../common/texts/FanGoTitle';

const WeddingVisualTablePlans = ({ weddingVisualTablePlans }) => {
  const { title, _rawSubTitle, images, features, featuresTitle } =
    weddingVisualTablePlans;

  const mobileView = useMediaQuery(`(max-width: 900px)`);
  const imagesRight = false;
  const textAlign = mobileView ? 'center' : 'left';

  const TitleComponent = (
    <FanGoTitle
      key={title}
      title={title}
      textAlign={textAlign}
      marginBottom={4}
      color={styles.deepGreen}
    />
  );

  const FeatureComponents = features.map((feature) => {
    const { title, subTitle } = feature;

    return (
      <div
        key={title}
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'center',
          marginBottom: 15,
        }}
      >
        <div>
          <Typography variant="h5" sx={{ textAlign: textAlign, mb: 0.5 }}>
            {title}
          </Typography>
          <Typography sx={{ textAlign: textAlign }}>{subTitle}</Typography>
        </div>
      </div>
    );
  });

  const textComponents = [TitleComponent, FeatureComponents];

  return (
    <FanGoBody alternate>
      <SplitTextAndImageColumns
        textComponents={textComponents}
        images={images}
        imagesRight={imagesRight}
      />
    </FanGoBody>
  );
};

export default WeddingVisualTablePlans;
