import React from 'react';

import { Box } from '@mui/material';

import FanGoBody from '../../common/body/FanGoBody';
import { FanGoTextProcessor } from '../../common/portableText';
import styles from '../../common/styling/styles';
import getTestimonialCards from '../../common/testimonials/getTestimonialCards';
import { MainText } from '../../common/texts';
import BlogsCarousel from '../CommonComps/blogsPreview/BlogsCarousel';

const WeddingTestimonials = ({ testimonials }) => {
  const { title, clientTestimonials, _rawBody } = testimonials;

  const cardArray = getTestimonialCards({
    testimonials: clientTestimonials,
  });

  return (
    <FanGoBody>
      <MainText textVariant="h2" value={title} fontColor={styles.darkBlue} />
      <FanGoTextProcessor text={_rawBody} textAlign="center" />
      <Box sx={{ mt: 3, pb: 3 }}>
        <BlogsCarousel array={cardArray} />
      </Box>
    </FanGoBody>
  );
};

export default WeddingTestimonials;
