import React from 'react';

import { Box, Divider } from '@mui/material';

import styles from '../../common/styling/styles';
import { MainText, SubText } from '../../common/texts';
import { truncateText } from '../../common/utils';
import BlogCategoryDate from './BlogCategoryDate';

const BlogTitleExcerpt = ({
  title,
  excerpt,
  bodyMarginY = styles.allThree,
  textAlignment = styles.alignStyleLLLL,
  headerVariant = 'h1',
  headerFontWeight = null,
  bodyVariant = 'subtitle1',
  headerColor = styles.deepGreen,
  bodyColor = styles.grey,
  excerptLimit = 130,
  category,
  date,
}) => {
  return (
    <Box sx={{ p: 1, height: '100%' }}>
      <MainText
        textVariant={headerVariant}
        textAlignment={textAlignment}
        fontWeight={headerFontWeight || undefined}
        fontColor={headerColor}
        value={title ? truncateText(title, 65) : ''}
      />

      <SubText
        variant={bodyVariant}
        marginY={styles.allZero}
        fontColor={bodyColor}
        textAlignment={textAlignment}
        value={excerpt ? truncateText(excerpt, excerptLimit) : ''}
      />
    </Box>
  );
};

export default BlogTitleExcerpt;
